import type { FC } from 'react';
import type { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { LOCATIONS } from '@utils/constants';

type AlternateSlugs = {
  en: string;
  de: string;
  ru: string;
};

type Locale = keyof AlternateSlugs;

export interface HreflangHelmetProps {
  alternateCatSlugs?: AlternateSlugs;
  alternateSlugs?: AlternateSlugs;
  location: PageProps['location'];
  pageType: 'blog' | 'help' | 'category' | 'default';
  isMobileFaq: boolean;
}

const locales: Locale[] = ['ru', 'en', 'de'];

export const HreflangHelmet: FC<HreflangHelmetProps> = ({
  pageType,
  location,
  alternateSlugs = undefined,
  alternateCatSlugs = undefined,
  isMobileFaq
}) => {
  const PageTypeSwitch = () => {
    const pathPrefix = location.pathname.includes(LOCATIONS.TRANSFERS_EUROPE)
      ? LOCATIONS.TRANSFERS_EUROPE
      : LOCATIONS.ROOT;
    switch (pageType) {
      case 'blog':
        return (
          alternateSlugs &&
          Object.keys(alternateSlugs)
            .filter((locale) => !!alternateSlugs[locale as Locale])
            .map((locale) => {
              const href = `${location.origin}${pathPrefix}${
                locale === 'ru' ? '' : `${locale}/`
              }blog/${alternateSlugs[locale as Locale]}`;
              return <link rel="alternate" href={href} hrefLang={locale} />;
            })
        );
      case 'help':
        return (
          alternateSlugs &&
          alternateCatSlugs &&
          Object.keys(alternateSlugs)
            .filter(
              (locale) =>
                !!alternateCatSlugs[locale as Locale] && !!alternateSlugs[locale as Locale]
            )
            .map((locale) => {
              const href = `${location.origin}${pathPrefix}${locale === 'ru' ? '' : `${locale}/`}${
                isMobileFaq ? 'mfaq' : 'help'
              }/${alternateCatSlugs[locale as Locale]}/${alternateSlugs[locale as Locale]}`;
              return <link rel="alternate" href={href} hrefLang={locale} />;
            })
        );
      case 'category':
        return (
          alternateSlugs &&
          alternateCatSlugs &&
          Object.keys(alternateSlugs)
            .filter((locale) => !!alternateCatSlugs[locale as Locale])
            .map((locale) => {
              const href = `${location.origin}${pathPrefix}${locale === 'ru' ? '' : `${locale}/`}${
                isMobileFaq ? 'mfaq' : 'help'
              }/${alternateCatSlugs[locale as Locale]}`;
              return <link rel="alternate" href={href} hrefLang={locale} />;
            })
        );
      default:
        return locales.map((locale) => {
          const href = `${location.origin}${pathPrefix}${
            locale === 'ru' ? '' : `${locale}/`
          }${location.pathname.replace(pathPrefix, '')}`;
          return <link rel="alternate" href={href} hrefLang={locale} />;
        });
    }
  };

  return <Helmet>{PageTypeSwitch()}</Helmet>;
};
