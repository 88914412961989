import React from 'react'
import styles from './popup.module.scss'

class Popup extends React.Component {
    componentDidMount() {
        const {closeOnClickOutside} = this.props;
        if (closeOnClickOutside) {
            document.addEventListener('mousedown', this.handleClickOutside, false);
        }
    }

    componentWillUnmount() {
        const {closeOnClickOutside} = this.props;
        if (closeOnClickOutside) {
            document.removeEventListener('mousedown', this.handleClickOutside, false);
        }
    }


    handleClickOutside = event => {
        const {closeOnClickOutside} = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && closeOnClickOutside) {
            this.props.onClosePopup();
        }
    };


    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    render() {
        const {isOpen = false, onClosePopup, children} = this.props;
        if (!isOpen) {
            return null
        }

        return (
            <div className={styles['popup']} onClick={(e) => this.handleClickOutside(e)}>
                <div role="dialog" className={styles['popup_container']} ref={node => this.setWrapperRef(node)}>
                    <button className={styles['close']} onClick={onClosePopup}/>
                    {children}
                </div>
            </div>
        )
    }
}

export default Popup
