export const googlePlayUrl = {
  en:
    "http://app.appsflyer.com/ru.tsk.ftc.bender.qpay/?pid=koronapay-eu-en&c=direct-site&af_channel=site",
  ru:
    "http://app.appsflyer.com/ru.tsk.ftc.bender.qpay/?pid=koronapay-eu-ru&c=direct-site&af_channel=site",
};

export const appStoreLinks = {
  en:
    "http://app.appsflyer.com/1117334497/?pid=koronapay-eu-en&c=direct-site&af_channel=site",
  ru:
    "http://app.appsflyer.com/1117334497/?pid=koronapay-eu-ru&c=direct-site&af_channel=site",
};
